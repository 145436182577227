/**
 * @file Entry.js
 * @description Konva App Entry
 */

import Konva from 'konva';

import drawAreas from './Areas';

import drawNodes from './Nodes';

import drawDataLabels from './DataLabels';

export default class MTGPanorama {
    container = null;
    stage = null;

    dataLabels = null;
    // 画布尺寸发生变化导致图形重绘时，需要将外部传入数据也带入绘制流程中
    dataSourceCopy = null;

    constructor(containerId, options = {}) {
        this.container = document.getElementById(containerId);
        this.stage = new Konva.Stage({
            container: containerId,
            width: options.width || this.container.clientWidth,
            height: options.height || this.container.clientHeight,
        });
        this.draw();
    }

    setStageSize(width, height) {
        this.stage.width(width);
        this.stage.height(height);
        this.refresh();
    }

    draw() {
        drawAreas(this.stage);
        drawNodes(this.stage);
        this.dataLabels = drawDataLabels(this.stage);
    }

    refresh() {
        this.stage.destroyChildren();
        this.draw();
        this.setData(this.dataSourceCopy);
    }

    setData(dataSource) {
        this.dataSourceCopy = dataSource;
        this.dataLabels.setData(dataSource);
    }

    onLabelClick(callback) {
        this.dataLabels.onLabelClick(callback);
    }
}
