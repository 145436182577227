/**
 * @file 数据标签层
 */

import Konva from 'konva';
import { sizeTool } from './Tools';

export default function draw(stage) {
    // 此处引入画布dom，用来控制标签hover时的鼠标样式
    let container = stage.container();
    const { size } = sizeTool(stage.width(), stage.height());

    let layer = new Konva.Layer();

    let state = {};

    const labelFontSize = size(22);

    function onLabelMouseEnter(e) {
        container.style.cursor = 'pointer';
        let key = e.target.name();
        if (!state[key]) {
            return;
        }
        e.target.textDecoration('underline');

        let isDiff = !!state[key].diff;
        if (!isDiff) {
            return;
        }
        let tipPos = e.target.absolutePosition();
        labelTipsGroup.absolutePosition({
            x: tipPos.x,
            y: tipPos.y + e.target.height(),
        });
        labelTipsGroup.show();
        labelTipsValue.text(state[key].value);
        labelTipsDiffValue.text(state[key].dst_value);
        labelTipsDiff.text(state[key].diff);
    }

    function update() {
        for (let key in state) {
            let v = state[key];
            if (!components[key]) {
                continue;
            }
            let labelText = components[key].getText();
            if (v.value.toString() !== labelText.text()) {
                labelText.text(v.value);
            }
            if (v.diff) {
                labelText.fill('red');
            }
        }
    }

    function createLabelInstance(
        x,
        y,
        transparent = true,
        fontSize = labelFontSize
    ) {
        let label = new Konva.Label({
            x: size(x),
            y: size(y),
        });
        if (!transparent) {
            label.add(
                new Konva.Tag({
                    fill: '#fff',
                })
            );
        }
        let text = new Konva.Text({
            x: 0,
            y: 0,
            text: '',
            fontSize,
        });
        label.add(text);

        text.on('mouseenter', onLabelMouseEnter);
        text.on('mouseout', e => {
            container.style.cursor = 'default';
            labelTipsGroup.hide();
            e.target.textDecoration('');
        });
        text.on('click', () => {
            labelClickCallback && labelClickCallback(text.name());
        });
        return label;
    }

    let components = {
        sdk_total_per: createLabelInstance(50, 296),
        sdk_total: createLabelInstance(50, 326),
        adx_total_per: createLabelInstance(50, 552),
        adx_total: createLabelInstance(50, 580),
        tracking_total_per: createLabelInstance(50, 798),
        tracking_total: createLabelInstance(50, 826),
        sdk_total_to_hb: createLabelInstance(270, 242, false),
        sdk_total_to_waterfall: createLabelInstance(270, 308, false),
        sdk_total_to_setting: createLabelInstance(270, 372, false),
        sdk_total_to_analytics: createLabelInstance(270, 438, false),
        adx_total_to_gateway: createLabelInstance(270, 578, false),
        adx_total_to_gateway_mvdsp: createLabelInstance(270, 642, false),
        tracking_total_to_tracking: createLabelInstance(190, 859, false),
        tracking_to_tracking_ng: createLabelInstance(780, 904, false),
        request_per: createLabelInstance(470, 128),
        request_total: createLabelInstance(590, 128),
        request_sdk_hb: createLabelInstance(610, 256),
        request_sdk_waterfall: createLabelInstance(610, 320),
        request_sdk_setting: createLabelInstance(610, 386),
        request_sdk_analytics: createLabelInstance(610, 454),
        request_adx_gateway: createLabelInstance(610, 594),
        request_tracking: createLabelInstance(630, 744),
        request_hb_to_madx: createLabelInstance(722, 228, false),
        request_waterfall_to_madx: createLabelInstance(722, 294, false),
        filter_per: createLabelInstance(870, 128),
        filter_total: createLabelInstance(1000, 128),
        filter_madx_total: createLabelInstance(827, 297, false),
        filter_madx_per: createLabelInstance(850, 390),
        filter_madx_to_pioneer: createLabelInstance(923, 340, false),
        filter_pioneer_per: createLabelInstance(1010, 390),
        filter_pioneer_total: createLabelInstance(1050, 420),
        filter_madx_to_dsp_enternal_dsp: createLabelInstance(1000, 194, false),
        filter_pioneer_to_dsp_multi: createLabelInstance(1106, 357, false),
        dsp_per: createLabelInstance(1228, 128),
        dsp_total: createLabelInstance(1380, 128),
        dsp_pivot_juno: createLabelInstance(1380, 288),
        dsp_rt_dsp: createLabelInstance(1380, 440),
        dsp_make_money: createLabelInstance(1380, 514),
        dsp_mvdsp_juno: createLabelInstance(1380, 590),
        ranker_per: createLabelInstance(1610, 128),
        ranker_total: createLabelInstance(1750, 128),
        ranker_rs_per: createLabelInstance(1748, 286),
        ranker_rs_total: createLabelInstance(1840, 280, false),
        ranker_drs: createLabelInstance(1748, 364),
        ranker_rt_drs: createLabelInstance(1748, 440),
        common_per: createLabelInstance(1440, 770),
        common_total: createLabelInstance(1550, 770),
        common_mongo_redis: createLabelInstance(1222, 892),
        common_log_transfer: createLabelInstance(1360, 892),
        common_dmp_server: createLabelInstance(1490, 892),
        common_report_service: createLabelInstance(1628, 892),
        common_portal: createLabelInstance(1762, 892),
        common_other: createLabelInstance(1510, 992),
    };

    for (let key in components) {
        let label = components[key];
        label.getText().name(key);
        layer.add(label);
    }

    let labelTipsGroup = new Konva.Group({
        x: 0,
        y: 0,
    });
    labelTipsGroup.hide();

    let labelTipsRect = new Konva.Rect({
        x: 0,
        y: 0,
        width: size(120),
        height: size(104),
        fill: '#fff',
        stroke: '#999',
        strokeWidth: 1,
    });
    labelTipsGroup.add(labelTipsRect);

    let labelTipsValue = new Konva.Text({
        x: size(10),
        y: size(10),
        text: '-',
        fontSize: labelFontSize,
    });
    let labelTipsDiffValue = new Konva.Text({
        x: size(10),
        y: size(40),
        text: '-',
        fontSize: labelFontSize,
    });
    let labelTipsDiff = new Konva.Text({
        x: size(10),
        y: size(70),
        text: '-',
        fontSize: labelFontSize,
    });
    labelTipsGroup.add(labelTipsValue);
    labelTipsGroup.add(labelTipsDiffValue);
    labelTipsGroup.add(labelTipsDiff);

    layer.add(labelTipsGroup);

    stage.add(layer);

    let labelClickCallback = null;

    return {
        layer,
        setData(data) {
            state = data;
            update();
        },
        onLabelClick(listener) {
            labelClickCallback = listener;
        },
    };
}
