<template>
    <div class="ops-view">
        <div class="ctrl-view">
            <div class="label">年：</div>
            <n-select
                class="input"
                style="width: 80px"
                v-model:value="selectYear"
                :options="avaliableYear"
                size="small"
                @update:value="onYearChange"
            >
            </n-select>
            <div class="label">月：</div>
            <n-select
                class="input"
                style="width: 80px"
                v-model:value="selectMonth"
                :options="avaliableMonth"
                size="small"
            >
            </n-select>
            <n-button
                type="primary"
                size="small"
                :loading="loading"
                @click="onSearchBtnClick"
                >查询</n-button
            >
        </div>
        <div id="konva_paper" :style="{ height: paperHeight + 'px' }"></div>
        <!-- <n-modal v-model:show="showLabelDetail">
            <n-card style="width: 800px" :title="labelKey">
                <json-viewer
                    :value="labelDetail"
                    :expand-depth="9"
                    :copyable="{ copyText: '复制' }"
                    boxed
                ></json-viewer>
            </n-card>
        </n-modal> -->
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';

.ops-view {
    .common-content;

    .ctrl-view {
        position: relative;
        border: 1px dotted #ddd;
        padding: 4px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;

        .label {
            font-weight: bold;
            white-space: nowrap;
        }

        .input {
            margin-right: 10px;
        }
    }
}
</style>

<script setup>
import { ref, onMounted, nextTick, computed } from 'vue';

import { NSelect, NButton, NModal, NCard, useMessage } from 'naive-ui';

// import JsonViewer from 'vue-json-viewer';

import { useUserStore } from '@/stores/global';

import MTGPanorama from './Konva/Entry';

import { getBusinessCosts, getBusinessAvaliableDate } from '@/common/API';

// let data = {
//     sdk_total_per: { value: '5.68k/d', detail: { aaa: 111, bbb: 222 } },
//     sdk_total: { value: '(17w)' },
//     adx_total_per: { value: '8.23k/d' },
//     adx_total: { value: '(25w)' },
//     tracking_total_per: { value: '1.3k/d' },
//     tracking_total: { value: '(4w)' },
//     sdk_total_to_hb: { value: '1.58k/d' },
//     sdk_total_to_waterfall: { value: '1.15k/d' },
//     sdk_total_to_setting: { value: '1.28k/d' },
//     sdk_total_to_analytics: { value: '1.67k/d' },
//     adx_total_to_gateway: { value: '3.58k/d' },
//     adx_total_to_gateway_mvdsp: { value: '4.65k/d' },
//     tracking_total_to_tracking: { value: '1.3k/d' },
//     tracking_to_tracking_ng: { value: '0.15k/d' },
//     request_per: { value: '19.08k/d' },
//     request_total: { value: '(57w)' },
//     request_sdk_hb: { value: '1.13k/d' },
//     request_sdk_waterfall: { value: '0.48k/d' },
//     request_sdk_setting: { value: '0.15k/d' },
//     request_sdk_analytics: { value: '0.32k/d' },
//     request_adx_gateway: { value: '0.54k/d' },
//     request_tracking: { value: '0.86k/d' },
//     filter_per: { value: '5.78k/d' },
//     filter_total: { value: '(17w)' },
//     filter_madx_total: { value: '8w' },
//     filter_madx_per: { value: '0.33k/d' },
//     filter_madx_to_pioneer: { value: '0.67k/d' },
//     filter_pioneer_per: { value: '0.76k/d' },
//     filter_pioneer_total: { value: '9w' },
//     filter_madx_to_dsp_enternal_dsp: { value: '2.16k/d' },
//     filter_pioneer_to_dsp_multi: { value: '1.86k/d' },
//     dsp_per: { value: '18.63k/d' },
//     dsp_total: { value: '(56w)' },
//     dsp_pivot_juno: { value: '7.81k/d' },
//     dsp_rt_dsp: { value: '3.29k/d' },
//     dsp_make_money: { value: '0.57k/d' },
//     dsp_mvdsp_juno: { value: '6.96k/d' },
//     ranker_per: { value: '31.8k/d' },
//     ranker_total: { value: '(95w)' },
//     ranker_rs_per: { value: '20k/d' },
//     ranker_rs_total: { value: '57w' },
//     ranker_drs: { value: '11.8k/d' },
//     ranker_rt_drs: { value: '33w' },
//     common_per: { value: '25.4k/d' },
//     common_total: { value: '(76w)' },
//     common_mongo_redis: { value: '1.88k/d' },
//     common_log_transfer: { value: '12.3k/d' },
//     common_dmp_server: { value: '2.36k/d' },
//     common_report_service: { value: '1.29k/d' },
//     common_portal: { value: '0.47k/d' },
//     common_other: { value: '7.1k/d' },
// };

// naive ui message
const message = useMessage();

const store = useUserStore();

let paperHeight = ref(99);

const avaliableDate = ref({});
const avaliableYear = computed(() => {
    return Object.keys(avaliableDate.value).map(item => {
        return { label: item, value: item };
    });
});
const avaliableMonth = computed(() => {
    let months = avaliableDate.value[selectYear.value];
    if (!months) {
        return [];
    }
    return avaliableDate.value[selectYear.value].map(item => {
        return { label: item, value: item };
    });
});

const selectYear = ref(null);
const selectMonth = ref(null);
const loading = ref(true);

function onYearChange() {
    selectMonth.value = avaliableDate.value[selectYear.value][0];
}

const braceWLabels = [
    'sdk_total',
    'adx_total',
    'tracking_total',
    'request_total',
    'filter_total',
    'dsp_total',
    'ranker_total',
    'common_total',
];

const normalWLabels = [
    'filter_madx_total',
    'filter_pioneer_total',
    'ranker_rs_total',
    'ranker_rt_drs',
];

function valueConverter(data) {
    const converter = {
        default(v) {
            return `${(v / 1000).toFixed(2)}k/日`;
        },
        normalW(v) {
            return `${(v / 10000).toFixed(2)}w`;
        },
        braceW(v) {
            return `(${(v / 10000).toFixed(2)}w)`;
        },
    };
    for (let key in data) {
        switch (true) {
            case braceWLabels.includes(key):
                data[key].value = converter.braceW(data[key].value);
                break;
            case normalWLabels.includes(key):
                data[key].value = converter.normalW(data[key].value);
                break;
            default:
                data[key].value = converter.default(data[key].value);
                break;
        }
    }
    return data;
}

function onSearchBtnClick() {
    loading.value = true;
    getBusinessCosts(selectYear.value, selectMonth.value).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        mtg.setData(valueConverter(res.data));
    });
}

getBusinessAvaliableDate()
    .then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        avaliableDate.value = res.data;
        let yearKeys = Object.keys(avaliableDate.value);
        selectYear.value = yearKeys[yearKeys.length - 1];
        selectMonth.value = avaliableDate.value[selectYear.value][0];
        return getBusinessCosts(selectYear.value, selectMonth.value);
    })
    .then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        // 如果是首屏打开的页面，无法在onMounted阶段就init成功，需要在网络请求完成之后，再init
        initMTGPanorama();
        setTimeout(() => {
            mtg.setData(valueConverter(res.data));
            mtg.onLabelClick(name => {
                showLabelDetail.value = true;
                labelKey.value = name;
                labelDetail.value = res.data[name].detail || {};
            });
        }, 0);
    });

const labelDetail = ref({});

let showLabelDetail = ref(false);
let labelKey = ref('');

const paperId = 'konva_paper';
let mtg = null;
let dom = null;

function initMTGPanorama() {
    if (mtg) {
        return;
    }
    dom = document.getElementById(paperId);
    paperHeight.value = (dom.clientWidth * 1080) / 1920;
    nextTick(() => {
        mtg = new MTGPanorama(paperId);
    });
    window.addEventListener('resize', () => {
        paperHeight.value = (dom.clientWidth * 1080) / 1920;
        mtg.setStageSize(dom.clientWidth, (dom.clientWidth * 1080) / 1920);
    });
}

onMounted(() => {
    // 未登录用户界面不渲染全景图
    if (store.currentUser.roles.length < 1) {
        return;
    }
    initMTGPanorama();
});
</script>
