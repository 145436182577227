/**
 * @file MTG Areas Graph
 */

import Konva from 'konva';
import { sizeTool } from './Tools';

export default function draw(stage) {
    const { size } = sizeTool(stage.width(), stage.height());

    let layer = new Konva.Layer();

    let totalText1 = new Konva.Text({
        x: size(50),
        y: size(256),
        text: '汇总',
        fontSize: size(26),
        fontStyle: 'bold',
    });
    let totalText2 = new Konva.Text({
        x: size(50),
        y: size(516),
        text: '汇总',
        fontSize: size(26),
        fontStyle: 'bold',
    });
    let totalText3 = new Konva.Text({
        x: size(50),
        y: size(760),
        text: '汇总',
        fontSize: size(26),
        fontStyle: 'bold',
    });

    layer.add(totalText1);
    layer.add(totalText2);
    layer.add(totalText3);

    let requestArea = new Konva.Rect({
        x: size(387),
        y: size(68),
        width: size(360),
        height: size(960),
        fill: '#fff',
        stroke: '#000',
        strokeWidth: 1,
    });
    let requestTitle = new Konva.Text({
        x: size(530),
        y: size(86),
        text: '接入层',
        fontSize: size(24),
    });
    let requestSubBlock1 = new Konva.Rect({
        x: size(422),
        y: size(181),
        width: size(290),
        height: size(322),
        fill: '#cff',
        stroke: '#000',
        strokeWidth: 1,
    });
    requestSubBlock1.dash([4]);
    let requestSubBlock1Title = new Konva.Text({
        x: size(530),
        y: size(194),
        text: 'SDK流量',
        fontSize: size(20),
    });

    let requestSubBlock2 = new Konva.Rect({
        x: size(422),
        y: size(519),
        width: size(290),
        height: size(198),
        fill: '#cff',
        stroke: '#000',
        strokeWidth: 1,
    });
    requestSubBlock2.dash([4]);
    let requestSubBlock2Title = new Konva.Text({
        x: size(530),
        y: size(530),
        text: 'ADX流量',
        fontSize: size(20),
    });

    let requestSubBlock3 = new Konva.Rect({
        x: size(422),
        y: size(733),
        width: size(290),
        height: size(274),
        fill: '#cff',
        stroke: '#000',
        strokeWidth: 1,
    });
    requestSubBlock3.dash([4]);
    let requestSubBlock3Title = new Konva.Text({
        x: size(530),
        y: size(742),
        text: 'Tracking',
        fontSize: size(20),
    });

    layer.add(requestArea);
    layer.add(requestTitle);
    layer.add(requestSubBlock1);
    layer.add(requestSubBlock1Title);
    layer.add(requestSubBlock2);
    layer.add(requestSubBlock2Title);
    layer.add(requestSubBlock3);
    layer.add(requestSubBlock3Title);

    let filterArea = new Konva.Rect({
        x: size(811),
        y: size(68),
        width: size(312),
        height: size(632),
        fill: '#fff',
        stroke: '#000',
        strokeWidth: 1,
    });
    let filterTitle = new Konva.Text({
        x: size(876),
        y: size(86),
        text: '前置过滤&分发层',
        fontSize: size(24),
    });
    layer.add(filterArea);
    layer.add(filterTitle);

    let dspArea = new Konva.Rect({
        x: size(1187),
        y: size(68),
        width: size(312),
        height: size(632),
        fill: '#fff',
        stroke: '#000',
        strokeWidth: 1,
    });
    let dspTitle = new Konva.Text({
        x: size(1305),
        y: size(86),
        text: 'DSP层',
        fontSize: size(24),
    });
    layer.add(dspArea);
    layer.add(dspTitle);

    let rankArea = new Konva.Rect({
        x: size(1561),
        y: size(68),
        width: size(312),
        height: size(632),
        fill: '#fff',
        stroke: '#000',
        strokeWidth: 1,
    });
    let rankTitle = new Konva.Text({
        x: size(1680),
        y: size(86),
        text: 'Rank层',
        fontSize: size(24),
    });
    layer.add(rankArea);
    layer.add(rankTitle);

    let commonArea = new Konva.Rect({
        x: size(1187),
        y: size(717),
        width: size(687),
        height: size(310),
        fill: '#fff',
        stroke: '#000',
        strokeWidth: 1,
    });
    let commonTitle = new Konva.Text({
        x: size(1406),
        y: size(737),
        text: '公共层（不含大数据平台）',
        fontSize: size(24),
    });
    layer.add(commonArea);
    layer.add(commonTitle);

    stage.add(layer);
    return layer;
}
