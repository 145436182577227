/**
 * @file MTG Nodes Graph
 */

import Konva from 'konva';
import { sizeTool } from './Tools';

const SDK_list = [
    {
        text: 'HB',
    },
    {
        text: 'Waterfall',
    },
    {
        text: 'Setting',
    },
    {
        text: 'Analysis',
    },
];

const ADX_list = [
    {
        text: 'Gateway',
    },
    {
        text: 'Gateway\n(MvDSP)',
    },
];

const Tracking_list = [
    {
        text: 'Impression/\nClick',
    },
    {
        text: 'Install/Event',
    },
    {
        text: 'Track',
    },
];

const DSP_list = [
    {
        text: '外部DSP',
    },
    {
        text: 'Pivot/Juno',
    },
    {
        text: 'cnDSP',
    },
    {
        text: 'rtDSP',
    },
    {
        text: 'MakeMoney',
    },
    {
        text: 'MvDSP/juno',
        config: {
            dash: [3],
        },
    },
];

const Rank_list = [
    {
        text: 'RS',
    },
    {
        text: 'DRS',
    },
    {
        text: 'rtDRS',
    },
    {
        text: 'Saas\nDRS',
    },
];

export default function draw(stage) {
    const { size } = sizeTool(stage.width(), stage.height());
    const fontSize = size(16);
    let layer = new Konva.Layer();

    const defaultConfig = {
        width: size(253),
        height: size(55),
        fill: '#deebf7',
        stroke: '#000',
        strokeWidth: 1,
    };

    // *******************************************  节点绘制   *********************************************************

    function loopDraw(list, point, padding = 66, config = defaultConfig) {
        const { x, y } = point;
        const textX = 10;
        const textY = 6;
        list.forEach((item, index) => {
            const float = index * padding;
            let node = new Konva.Rect({
                x: size(x),
                y: size(y + float),
                ...item.config,
                ...config,
            });
            let text = new Konva.Text({
                x: size(x + textX),
                y: size(y + float + textY),
                text: item.text,
                fontSize,
                lineHeight: 1.3,
            });
            layer.add(node);
            layer.add(text);
        });
    }

    // 接入层
    loopDraw(SDK_list, { x: 441, y: 226 });
    loopDraw(ADX_list, { x: 441, y: 562 });
    loopDraw(Tracking_list, { x: 441, y: 777 });

    // DSP层
    loopDraw(DSP_list, { x: 1216, y: 179 }, 76);

    //Rank层
    loopDraw(Rank_list, { x: 1584, y: 255 }, 76);

    let nodeAjump = new Konva.Rect({
        x: size(874),
        y: size(762),
        width: size(253),
        height: size(55),
        fill: '#deebf7',
        stroke: '#000',
        strokeWidth: 1,
    });
    let nodeAjumpText = new Konva.Text({
        x: size(925),
        y: size(769),
        text: 'ajump\n(服务端上报imp/click)',
        fontSize,
        align: 'center',
        verticalAlign: 'middle',
        lineHeight: 1.3,
    });
    layer.add(nodeAjump);
    layer.add(nodeAjumpText);

    let nodeTrackingNg = new Konva.Rect({
        x: size(874),
        y: size(888),
        width: size(253),
        height: size(55),
        fill: '#deebf7',
        stroke: '#000',
        strokeWidth: 1,
    });
    let nodeTrackingNgText = new Konva.Text({
        x: size(960),
        y: size(895),
        text: 'trackingNg\n(归因匹配)',
        fontSize,
        align: 'center',
        verticalAlign: 'middle',
        lineHeight: 1.3,
    });
    layer.add(nodeTrackingNg);
    layer.add(nodeTrackingNgText);

    // 前置过滤
    let nodeMadx = new Konva.Rect({
        x: size(823),
        y: size(323),
        width: size(113),
        height: size(91),
        fill: '#deebf7',
        stroke: '#000',
        strokeWidth: 1,
    });
    let nodeMadxText = new Konva.Text({
        x: size(833),
        y: size(329),
        text: 'madx',
        fontSize,
        lineHeight: 1.3,
    });
    layer.add(nodeMadx);
    layer.add(nodeMadxText);

    let nodePioneer = new Konva.Rect({
        x: size(991),
        y: size(323),
        width: size(113),
        height: size(91),
        fill: '#deebf7',
        stroke: '#000',
        strokeWidth: 1,
    });
    let nodePioneerText = new Konva.Text({
        x: size(1001),
        y: size(329),
        text: 'Pioneer',
        fontSize,
        lineHeight: 1.3,
    });
    layer.add(nodePioneer);
    layer.add(nodePioneerText);

    let nodePioneerMvDSP = new Konva.Rect({
        x: size(991),
        y: size(585),
        width: size(113),
        height: size(73),
        fill: '#deebf7',
        stroke: '#000',
        strokeWidth: 1,
        dash: [3],
    });
    let nodePioneerMvDSPText = new Konva.Text({
        x: size(1015),
        y: size(597),
        text: 'Pioneer\n(MvDSP)',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodePioneerMvDSP);
    layer.add(nodePioneerMvDSPText);

    let nodeSDK = new Konva.Ellipse({
        x: size(1046),
        y: size(258),
        radiusX: size(66),
        radiusY: size(33),
        fill: '#fef2cc',
        stroke: '#000',
        strokeWidth: 1,
        dash: [3],
    });
    let nodeSDKText = new Konva.Text({
        x: size(1014),
        y: size(236),
        text: 'SDK算法\n前置过滤',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodeSDK);
    layer.add(nodeSDKText);

    let nodeEngineer = new Konva.Ellipse({
        x: size(1046),
        y: size(511),
        radiusX: size(66),
        radiusY: size(33),
        fill: '#fef2cc',
        stroke: '#000',
        strokeWidth: 1,
        dash: [3],
    });
    let nodeEngineerText = new Konva.Text({
        x: size(1010),
        y: size(489),
        text: '工程/算法\n前置过滤',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodeEngineer);
    layer.add(nodeEngineerText);

    // 公共层
    // 1202 802 113 84
    for (let i = 0; i < 5; i++) {
        let padding = 134;
        let node = new Konva.Rect({
            x: size(1202 + padding * i),
            y: size(802),
            width: size(113),
            height: size(84),
            fill: '#deebf7',
            stroke: '#000',
            strokeWidth: 1,
        });
        layer.add(node);
    }

    let nodeMongoText = new Konva.Text({
        x: size(1235),
        y: size(820),
        text: 'Mongo\n/Redis',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodeMongoText);

    let nodeLogText = new Konva.Text({
        x: size(1361),
        y: size(831),
        text: '日志传输',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodeLogText);

    let nodeDMPText = new Konva.Text({
        x: size(1505),
        y: size(820),
        text: 'DMP\nServer',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodeDMPText);

    let nodeReportText = new Konva.Text({
        x: size(1629),
        y: size(831),
        text: '报表服务',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodeReportText);

    let nodePortalText = new Konva.Text({
        x: size(1773),
        y: size(831),
        text: 'Portal',
        fontSize,
        lineHeight: 1.3,
        align: 'center',
    });
    layer.add(nodePortalText);

    // 1380 939 311 49
    let nodeOther = new Konva.Rect({
        x: size(1380),
        y: size(939),
        width: size(311),
        height: size(49),
        fill: '#deebf7',
        stroke: '#000',
        strokeWidth: 1,
    });
    let nodeOtherText = new Konva.Text({
        x: size(1520),
        y: size(955),
        text: '其他',
        fontSize,
        lineHeight: 1.3,
    });
    layer.add(nodeOther);
    layer.add(nodeOtherText);

    // *******************************************  箭头绘制   *********************************************************
    const drawArrow = drawArrowFactory.bind(null, size, layer);

    // SDK
    let SDKPoint = new Konva.Rect({
        x: size(50),
        y: size(357),
        width: size(34),
        height: size(24),
        fill: '#000',
    });
    layer.add(SDKPoint);
    drawArrow([84, 369, 186, 369, 186, 252, 438, 252]);
    drawArrow([186, 318, 438, 318]);
    drawArrow([186, 384, 438, 384]);
    drawArrow([186, 369, 186, 450, 438, 450]);

    // -> 前置过滤
    // -> madx
    drawArrow([695, 252, 763, 252, 763, 368, 820, 368]);
    drawArrow([695, 318, 763, 318, 763, 368, 820, 368]);
    // madx ->
    drawArrow([879, 323, 879, 206, 1212, 206]);
    drawArrow([936, 368, 989, 368]);
    // Pioneer ->
    drawArrow([1103, 368, 1155, 368, 1155, 282, 1212, 282]);
    drawArrow([1103, 368, 1155, 368, 1155, 358, 1212, 358]);
    drawArrow([1103, 368, 1155, 368, 1155, 434, 1212, 434]);
    drawArrow([1103, 368, 1155, 368, 1155, 510, 1212, 510]);
    drawArrow([1103, 368, 1155, 368, 1155, 586, 1212, 586]);
    drawArrow([1104, 621, 1155, 621, 1155, 586, 1212, 586]);
    // DSP -> Rank  1584, y: 255
    drawArrow([1468, 282, 1582, 282]);
    drawArrow([1468, 358, 1582, 358]);
    drawArrow([1468, 434, 1582, 434]);
    drawArrow([1468, 510, 1582, 510]);
    drawArrow([1468, 586, 1531, 586, 1531, 358, 1582, 358]);

    // SDK算法 ->
    drawArrow([1046, 291, 1046, 320]);
    // 工程算法 ->
    drawArrow([1046, 544, 1046, 580]);
    // ADX
    let ADXPoint = new Konva.Rect({
        x: size(50),
        y: size(609),
        width: size(34),
        height: size(24),
        fill: '#000',
    });
    layer.add(ADXPoint);
    drawArrow([84, 621, 186, 621, 186, 589, 438, 589]);
    drawArrow([186, 621, 186, 653, 438, 653]);
    // ADX -> 前置过滤
    drawArrow([695, 589, 879, 589, 879, 416], {
        ...arrowConfig,
        dash: [8],
    });
    drawArrow([713, 621, 989, 621]);

    // Tracking
    let TrackingPoint = new Konva.Rect({
        x: size(50),
        y: size(858),
        width: size(34),
        height: size(24),
        fill: '#000',
    });
    layer.add(TrackingPoint);
    drawArrow([84, 870, 417, 870]);
    drawArrow([715, 870, 797, 870, 797, 789, 870, 789]);
    drawArrow([715, 870, 797, 870, 797, 915, 870, 915]);

    stage.add(layer);
    return layer;
}

// 箭头默认配置
const arrowConfig = {
    stroke: '#000',
    fill: '#000',
    pointerLength: 8,
    pointerWidth: 6,
    pointerAtEnding: true,
};

// 绘制箭头
function drawArrowFactory(size, layer, pointList, config = arrowConfig) {
    let totalToHBArrow = new Konva.Arrow({
        points: pointList.map(item => size(item)),
        ...config,
    });
    layer.add(totalToHBArrow);
}
